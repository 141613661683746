<template>
  <b-container fluid class="p-0">
    <div class="carregando" v-if="preload">
      <div>
        <img v-if="!isHeaven" src="@/assets/img/_logo.svg" alt="logo-loading" class="logo-loading" />
        <img v-else src="@/assets/img/heaven/logo.svg" alt="logo-loading" class="logo-loading" />
        <span class="loading-carregando" :class="{'heaven': isHeaven}"></span>
      </div>
    </div>
  </b-container>
</template>

<script>
import LinkService from "@/services/resources/LinkService";
import { sanitizeUrl } from '@/utils/url.js';

const service = LinkService.build();

import Cookies from "js-cookie";

export default {
  name: 'Redirect',
  data() {
    return {
      preload: false,
    }
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    newRedirect(route) {
      this.preload = true;

      service
        .read({ id: route.params.page })
        .then((res) => {
          try {
            let affiliate = route.query.a_id;
            if (affiliate) {
              Cookies.set(`affiliate`, affiliate, {
                expires: 7,
                path: "/",
              });
            }
            if (affiliate) {
              Cookies.set(`affiliate_${res.product_id}`, affiliate, {
                expires: 7,
                path: "/",
              });
            }
          } catch (error) {
            console.error(console.error());
          }
          let redirectUrl = new URL(res.url);
          Object.keys(route.query).forEach((key) => {
            redirectUrl.searchParams.append(key, route.query[key]);
          });
          window.location.href = redirectUrl.href;
        })
        .catch((err) => {
          document
            .querySelector(".carregando")
            .classList.add("erro", "redirect");
          throw err;
        });
    },
  },

  created(){
    sanitizeUrl(this.$route);
  },

  mounted() {
    this.newRedirect(this.$route);
  },
};
</script>
